import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


export const Menu = ({apis, selectedApi, setSelectedApi}) => {
    const classes = useStyles();

    const handleOpenExternal = url => window.open(url, '_blank');

    const envList = api => {
        let listItems = [];
        if(api.beta) {
            listItems.push(
                <ListItem
                    className={classes.nested}
                    button
                    key={api.beta.id}
                    onClick={() => {
                        if(api.external) {
                            handleOpenExternal(api.beta.url)
                        } else {
                            setSelectedApi(api.beta.id)
                        }
                    }}
                    selected={api.beta.id === selectedApi}
                >
                    <ListItemText primary={api.beta.name} />
                </ListItem>
            )
        }

        if(api.prod) {
            listItems.push(
                <ListItem
                    className={classes.nested}
                    button
                    key={api.prod.id}
                    onClick={() => {
                        if(api.external) {
                            handleOpenExternal(api.prod.url)
                        } else {
                            setSelectedApi(api.prod.id)
                        }
                    }}
                    selected={api.prod.id === selectedApi}
                >
                    <ListItemText primary={api.prod.name} />
                </ListItem>
            )
        }
        return (
            <List component="div" disablePadding>
                {listItems}
            </List>
        )
    }

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div className={classes.toolbar} />
            <Divider />
            <List>
                {apis.map(api => (
                    <div key={api.id}>
                        <ListItem key={api.id}>
                            <ListItemText primary={api.name} />
                        </ListItem>
                        {envList(api)}
                    </div>
                ))}
            </List>
        </Drawer>
    )
}