export const getApis = () => {
    return [
        {
            name: "Museum API",
            external: true,
            beta: {
                id: 0,
                name: "beta",
                url:"https://beta-apigateway.dimu.org/museum-api/docs"
            },
            prod: {
                id: 1,
                name: "prod",
                url:"https://apigateway.dimu.org/museum-api/docs"
            }

        },
        {
            name: "DM Admin API",
            beta: {
                id: 2,
                name: "beta",
                url: "https://beta-apigateway.dimu.org/dm-admin-api/apispec_1.json"
            },
            prod: {
                id: 3,
                name: "prod",
                url: "https://apigateway.dimu.org/dm-admin-api/apispec_1.json"
            }
        },
        {
            name: "BPN API",
            beta: {
                id: 4,
                name: "beta",
                url: "https://beta-apigateway.dimu.org/bpn/apispec_1.json"
            }
        },
        {
            name: "Authorization API",
            external: true,
            beta: {
                id: 5,
                name: "beta",
                url: "https://beta-apigateway.dimu.org/authz/docs"
            },
            prod: {
                id: 6,
                name: "prod",
                url: "https://apigateway.dimu.org/authz/docs"
            }
        },
        {
            name: "Authentication API",
            beta: {
                id: 7,
                name: "beta",
                url: "https://beta-apigateway.dimu.org/auths/apispec_1.json"
            },
            prod: {
                id: 8,
                name: "prod",
                url: "https://apigateway.dimu.org/auths/apispec_1.json"
            }
        },
        {
            name: "Applications API",
            external: true,
            beta: {
                id: 9,
                name: "beta",
                url: "https://beta-apigateway.dimu.org/app-registry/docs"
            },
            prod: {
                id: 10,
                name: "prod",
                url: "https://apigateway.dimu.org/app-registry/docs"
            }
        },
        {
            name: "Notification API",
            beta: {
                id: 11,
                name: "beta",
                url: "https://beta-apigateway.dimu.org/notification/apispec_1.json"
            },
            prod: {
                id: 12,
                name: "prod",
                url: "https://apigateway.dimu.org/notification/apispec_1.json"
            }
        }
    ]
}