import React, {useState} from 'react';
import './App.css';
import SwaggerUI from 'swagger-ui-react'
import "swagger-ui-react/swagger-ui.css"
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import {Menu} from "./Menu";
import Typography from "@material-ui/core/Typography";
import {getApis} from "./getApis";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}));

export const App = () => {
    const [selectedApi, setSelectedApi] = useState(null)
    const apis = getApis();
    const classes = useStyles();

    const flattenApiUrls = () => apis.reduce((acc, object) => {
        let list = acc;
        if(object.beta) {
            list.push({id: object.beta.id, url: object.beta.url})
        }
        if(object.prod) {
            list.push({id: object.prod.id, url: object.prod.url})
        }
        return list;

    }, []);

    const getCurrentUrl = () => flattenApiUrls().find(api => api.id === selectedApi)
        ? flattenApiUrls().find(api => api.id === selectedApi).url
        : null

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Menu apis={apis} selectedApi={selectedApi} setSelectedApi={setSelectedApi} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {selectedApi !== null ? <SwaggerUI url={getCurrentUrl()}/> : <Typography>Select an API in the left menu</Typography>}
            </main>
        </div>
    )
}
